import { Group, Modal, Text, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ActionButton } from 'components/ActionButton';
import { TokenAmount } from 'components/TokenAmount';
import { useTranslation } from 'next-i18next';
import { useImportTokensForm } from './useImportTokenForm';

export function ImportTokens() {
  const { t } = useTranslation('common', {
    keyPrefix: 'importedTokens',
  });
  const { t: tCommon } = useTranslation('common');
  const [opened, { open, close: closeModal }] = useDisclosure(false);
  const {
    form,
    balance,
    onContractAddressBlur,
    onCoinGeckoIdBlur,
    onResetAndClose,
    onSubmit,
  } = useImportTokensForm(closeModal);

  return (
    <>
      <Modal
        opened={opened}
        onClose={onResetAndClose}
        title={t('importToken')}
        centered
      >
        <form onSubmit={onSubmit}>
          <TextInput
            placeholder={t('contractAddress')}
            label={t('contractAddress')}
            withAsterisk
            {...form.getInputProps('contractAddress')}
            onBlur={onContractAddressBlur}
          />
          <TextInput
            placeholder={t('coinGeckoId')}
            label={t('coinGeckoId')}
            minLength={3}
            {...form.getInputProps('coinGeckoId')}
            onBlur={onCoinGeckoIdBlur}
            mt={'md'}
          />

          <Text mt={'md'}>
            {'Token balance:'}{' '}
            <TokenAmount
              symbol={balance?.symbol}
              amount={balance?.balance}
              decimals={balance?.decimals}
              maxDecimals={4}
              isHidable={true}
              span
            />
          </Text>

          <Group align={'space-between'} justify={'space-between'} mt={'md'}>
            <ActionButton onClick={onResetAndClose} variant={'outline'}>
              {tCommon('cancel')}
            </ActionButton>
            <ActionButton
              type={'submit'}
              tt={'capitalize'}
              disabled={
                !form.isValid() ||
                !balance ||
                Object.keys(form.errors).length > 0
              }
            >
              {tCommon('save')}
            </ActionButton>
          </Group>
        </form>
      </Modal>

      <ActionButton onClick={open}>{t('importToken')}</ActionButton>
    </>
  );
}
